import React from 'react'
import moment from 'moment-timezone'

import { Item } from "../../../Item"

import { useCaseViewContext } from '../../../../context/CaseViewContext'

import {
    RequestHistoryItem,
    Category,
    RequestType,
    RequestStatus
} from './Request.styles'

export const Request = ({ request, index, navigateToCase }) => {
    const { caseDetails, activeRequest } = useCaseViewContext()
    const formattedDate = moment(request.timestamp).format('MM/DD/YYYY')

    const isActiveCase = caseDetails?._id === request.caseId

    const isActiveRequest = isActiveCase && (
        activeRequest?._id === request._id ||
        (!activeRequest && request.requestType === 'initial')
    )

    const activeRequestBackgroundColor = 'rgba(204, 125, 203, 0.15)'
    const activeRequestTextColor = 'rgba(204, 125, 203, 1)'
    const activeCaseBackgroundColor = 'rgba(123, 173, 126, 0.15)'
    const activeCaseTextColor = '#7BAD7E'
    const otherCaseBackgroundColor = '#ddd'
    const otherCaseTextColor = '#000'

    const backgroundColor = isActiveRequest
        ? activeRequestBackgroundColor
        : isActiveCase
            ? activeCaseBackgroundColor
            : otherCaseBackgroundColor

    const color = isActiveRequest
        ? activeRequestTextColor
        : isActiveCase
            ? activeCaseTextColor
            : otherCaseTextColor

    return (
        <Item
            key={index}
            label={formattedDate}
            value={
                <RequestHistoryItem
                    isactiverequest={isActiveRequest.toString()}
                    onClick={() => navigateToCase(request.caseId, request._id)}
                    backgroundcolor={backgroundColor}
                >
                    <Category color={color}>{request.teleMedicineCategory}</Category>
                    <RequestType>{request.requestType} {request.requestType.toLowerCase() !== 'rx change' && 'consultation'}</RequestType>
                    <RequestStatus>{request.status.toLowerCase()}</RequestStatus>
                </RequestHistoryItem>
            }
        />
    )
}