import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const navigateByURL = (url) => {
    if (url) history.push(`/${ url }`);
}

export const NavURLs = {
    dashboard: 'dashboard',
    caseQueue: 'dashboard/physician/case-queue',
    messages: 'dashboard/physician/messages'
}