import React from "react";
import { Modal, Button, message } from "antd";
import PrescriptionsAPI from "../../../axios/prescription/PrescriptionsApi"
import { RxErrorType } from "../../../types/enums"
import { useHistory } from 'react-router-dom'

const RxErrorModal = ({
    selectedRxError,
    selectRxError,
    fetchRxErrors
}) => {
    const history = useHistory();
    const caseId = selectedRxError.prescription?.externalMetadata?.caseId
    const errorId = selectedRxError?._id

    const handleIgnore = async () => {
        try {
            const { data: { result } } = await PrescriptionsAPI.acknowledgeError(selectedRxError._id)
            if (result.success) {
                message.success('Error ignored.')
                fetchRxErrors()
                selectRxError(null)
            } else message.error('Failed to ignore error')
        } catch (e) {
            console.log(e)
            message.error('Failed to ignore error')
        }
    }

    const handleRequeue = async () => {
        if (!!selectedRxError.prescription) {
            try {
                const { data } = await PrescriptionsAPI.resendPrescription({ prescriptionId: selectedRxError.prescription.id })
                if (data.success) return message.success('Prescription requeued.')
                throw new Error(data.message)
            } catch (e) {
                message.error(e.message || 'Failed to requeue prescription')
            }
        } else message.error('Unable to requeue prescription. Contact admin to resolve.')
    }

    const handleRollback = async () => {
        if (caseId) {
            try {
                const { data } = await PrescriptionsAPI.rollBackCase(caseId, errorId)
                if (!data.error) {
                    message.success('Case rolled back.')
                    return history.push(`/dashboard/physician/caseview/${caseId}`,)
                }

                throw new Error(data.message)
            } catch (e) {
                message.error(e.message || 'Failed to roll back case')
            }
        } else message.error('Unable to roll back case. Contact admin to resolve.')
    }

    const IgnoreButton = () => <Button key="ignore" type="danger" onClick={handleIgnore}>Ignore</Button>
    const RequeueButton = () => <Button key="requeue" type="primary" onClick={handleRequeue}>Requeue</Button>
    const RollbackButton = () => <Button key="requeue" type="primary" onClick={handleRollback}>Correct Rx</Button>

    return (
        <Modal
            visible={!!selectedRxError}
            title={<span style={{ textTransform: 'capitalize'}}>{selectedRxError.errorType?.toLowerCase() || 'Error'}</span>}
            onCancel={() => selectRxError(null)}
            width={1200}
            footer={[
                <IgnoreButton />,
                selectedRxError.errorType === RxErrorType.TRANSMISSION_ERROR ? <RequeueButton /> : <RollbackButton />
            ]}
        >
            <div style={{
                margin: '0 5px',
                padding: '10px 80px',
                display: 'flex',
                background: '#FAFBFC'
            }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bolder', marginRight: '5px' }}>Error Details</span>
                    { selectedRxError.details }
                </div>
            </div>
        </Modal>
    );
};

export default RxErrorModal;
