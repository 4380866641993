import React, { useEffect, useRef } from "react"

import {
    ThumbnailImage,
 } from "../../../../Styles"

 export const Thumbnail = ({ src, alt }) => {
    const thumbnailRef = useRef(null);

    useEffect(() => {
        const current = thumbnailRef.current;

        const scaleUp = () => {
            thumbnailRef.current.style.transform = "scale(3)";
            current.style.zIndex = '3';
        }

        const scaleDown = () => {
            thumbnailRef.current.style.transform = "scale(1)";
            setTimeout(() => {
                current.style.zIndex = '1';
            }, 300)
        }

        current.addEventListener("mouseenter", scaleUp);
        current.addEventListener("mouseleave", scaleDown);

        return () => {
            if (current) {
                current.removeEventListener("mouseenter", scaleUp)
                current.removeEventListener("mouseleave", scaleDown)
            }
        }
    }, []);

    return (
        <ThumbnailImage
            ref={thumbnailRef}
            alt={`${alt} thumbnail`}
            src={src}
        />
    )
}
