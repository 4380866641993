/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Handlebars from "handlebars";
import { WarningOutlined } from "@ant-design/icons";

import {
  Radio,
  Typography,
  Modal,
  Form,
  Select,
  Spin,
  message,
  Checkbox,
  Input
} from "antd";

import { LoadingOutlined, StarFilled } from "@ant-design/icons";
import { AntTinyWrapper } from "../../../../../../../components/AntTinyWrapper";

import incompleteCases from "../../../../../../../redux/incompleteCases";

import decodeToken from "../../../../../../../utils/decodeToken";
import PhysicianAPI from "../../../../../../../axios/physician/PhysicianApi";
import { CaseStatusEnum, PhysicianTagEnum } from "../../../../../../../types/enums";

import { useCaseViewContext } from "../../../../../context/CaseViewContext";
import { navigateByURL, NavURLs } from "../../../../../../../helpers";

const { Option } = Select;
const { Text } = Typography;

export const UpdateStatusModal = ({
  modalVisibility,
  actionCaseStatus,
  setCaseActionStatus,
}) => {
  const dispatch = useDispatch();

  const { 
    caseDetails, 
    activeRequest,
    fetchCaseDetails, 
    provider 
  } = useCaseViewContext()

  const state = useSelector(state => state);
  const redirectURL = state.physicianDetails.physicianDetails?.caseCompleteNavURL;
  
  const [form] = Form.useForm();

  const PROVIDER_NEEDS_REVIEW = provider?.tags?.includes(PhysicianTagEnum.NEEDS_REVIEW)

  const initialState = {
    selectedTemplateMessage: "",
    commonTemplateMessages: [],
    modalload: false,
    disableMessage: true,
    patientExplanation: null,
    requireInformationStatus: null,
    sendInformationCheckBox: actionCaseStatus !== CaseStatusEnum.REFER,
    templateFilter: "My",
    rejectionReason: null,
  };

  const [CaseUpdateStatusState, setCaseUpdateStatusState] =
    useState(initialState);

  const user = useMemo(() => {
    const decodedToken = decodeToken(localStorage.getItem("token"));
    return decodedToken?.user;
  }, []);

  const sortFavorites = (templates) => {
    return [...templates].sort((a) => (a.favoritedBy ? -1 : 1));
  };

  const compileHandlebars = (message) => {
    const handlebarsTemplate = Handlebars.compile(message);

    return handlebarsTemplate({
      case: caseDetails,
      physician: provider,
      patient: caseDetails?.patient,
    });
  };

  const requiresInformation = [
    {
      _id: "5fc088f0dc6ef1ea454e2df2",
      name: "invalid face photo",
      displayName: CaseStatusEnum.INVALID_FACE_PHOTO,
    },
    {
      _id: "5fc08922dc6ef1ea454e2df3",
      name: "invalid photo id",
      displayName: CaseStatusEnum.INVALID_PHOTO_ID,
    },
    {
      _id: "5eeb23518a1eef7389dc1e84",
      name: "additional info required",
      displayName: CaseStatusEnum.ADDITIONAL_INFO_REQUIRED,
    },
  ];

  useEffect(() => {
    setCaseUpdateStatusState((prev) => ({ ...prev, disableMessage: true }));
    if (
      modalVisibility &&
      actionCaseStatus !== CaseStatusEnum.ADDITIONAL_INFO_REQUIRED
    ) {
      getTemplateMessages(actionCaseStatus, caseDetails?.categoryId);
      setCaseUpdateStatusState((prev) => ({ ...prev, disableMessage: false }));
    }

    if (
      modalVisibility &&
      actionCaseStatus === CaseStatusEnum.ADDITIONAL_INFO_REQUIRED
    ) {
      setCaseUpdateStatusState((prev) => ({ ...prev, modalload: false }));
      if (CaseUpdateStatusState.requireInformationStatus) {
        getTemplateMessages(
          CaseUpdateStatusState.requireInformationStatus,
          caseDetails?.categoryId
        );
        setCaseUpdateStatusState((prev) => ({
          ...prev,
          disableMessage: false,
        }));
      }
    }
  }, [
    modalVisibility,
    actionCaseStatus,
    caseDetails?.categoryId,
    CaseUpdateStatusState.requireInformationStatus,
  ]);

  function getTemplateMessages(status, category) {
    const filters = {
      categoryIds: [category],
      status: status,
    };
    setCaseUpdateStatusState((prev) => ({ ...prev, modalload: true }));
    PhysicianAPI.getTemplates("case", { filters })
      .then((response) => {
        setCaseUpdateStatusState((prev) => ({
          ...prev,
          commonTemplateMessages: sortFavorites(response.data.templates),
          modalload: false,
        }));
      })
      .catch((error) => {
        setCaseUpdateStatusState((prev) => ({ ...prev, modalload: false }));
      });
  }

  const handlePatientExplanationChange = (value) => {
    setCaseUpdateStatusState((prev) => ({
      ...prev,
      patientExplanation: value,
    }));
    if (value !== CaseUpdateStatusState.selectedTemplateMessage) {
      setCaseUpdateStatusState((prev) => ({
        ...prev,
        selectedTemplateMessage: "",
      }));
    }
  };

  const handleRequireInformationChange = (value) => {
    setCaseUpdateStatusState((prev) => ({
      ...prev,
      requireInformationStatus: value,
    }));
  };

  const handleTemplateSelectionChange = (value) => {
    setCaseUpdateStatusState((prev) => ({
      ...prev,
      selectedTemplateMessage: value,
      patientExplanation: value,
    }));

    form.setFieldsValue({ "Patient Explanation": value });
  };

  const handleCancel = () => {
    setCaseUpdateStatusState(initialState);
    setCaseActionStatus(null);
    form.resetFields();
  };

  const handleStatusUpdate = () => {
    let statusData = {
      patientExplanation: CaseUpdateStatusState.patientExplanation,
      sendToPatient: CaseUpdateStatusState.sendInformationCheckBox,
      requestId: activeRequest?._id,
    };

    if (actionCaseStatus === CaseStatusEnum.ADDITIONAL_INFO_REQUIRED) {
      statusData.status = CaseUpdateStatusState.requireInformationStatus;
    } else {
      statusData.status = actionCaseStatus;
      actionCaseStatus === CaseStatusEnum.REFER &&
        (statusData.rejectionReason = CaseUpdateStatusState.rejectionReason);
    }

    setCaseUpdateStatusState((prev) => ({ ...prev, modalload: true }));

    PhysicianAPI.updateCaseStatus(caseDetails?._id, statusData)
      .then(({ data }) => {
        if (!data || !data.success) throw new Error(data?.message || null);
        setCaseActionStatus(null);
        message.success("Successfully updated case status");
        navigateByURL(redirectURL || NavURLs.caseQueue);
        fetchCaseDetails();
        dispatch(incompleteCases.actions.getIncompleteCases())
      })
      .catch((error) => {
        message.error(
          error.response?.data?.message ||
          "Unable to update case status. Please try again later!"
        );
      })
      .finally(() => setCaseUpdateStatusState(initialState));
  };

  const handleRejectionReasonChange = ({ target }) => {
      setCaseUpdateStatusState((prev) => ({
        ...prev,
        rejectionReason: target.value,
      }))
  }

  return (
    <Modal
      visible={modalVisibility}
      destroyOnClose={true}
      onOk={handleStatusUpdate}
      onCancel={handleCancel}
      width="35vw"
      okButtonProps={{
        disabled:
          (actionCaseStatus === CaseStatusEnum.REFER &&
            !CaseUpdateStatusState.rejectionReason) ||
          !CaseUpdateStatusState.patientExplanation,
      }}
      okText={
        PROVIDER_NEEDS_REVIEW && actionCaseStatus === CaseStatusEnum.REFER
          ? "Refer"
          : "Update Status"
      }
    >
      <Spin
        spinning={CaseUpdateStatusState.modalload}
        indicator={<LoadingOutlined />}
      >
        <Form form={form} name="update case status modal">
          {actionCaseStatus !== CaseStatusEnum.ADDITIONAL_INFO_REQUIRED ? (
            <Form.Item
              label="Selected Case Status"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              
              <Text
                style={{ textTransform: "capitalize", fontWeight: "bolder" }}
              >
                {actionCaseStatus}
              </Text>
              { caseDetails?.status === CaseStatusEnum.CONSULT_COMPLETE && (
                  <div style={{ marginTop: '5px'}}>
                    <Text>
                      <WarningOutlined
                          style={{ color: "red", fontSize: "20px", marginRight: "5px" }}
                      />
                      This case is currently prescribed. Please be sure you've canceled all outstanding prescriptions before changing the status to Consult Complete No Rx
                    </Text>
                  </div>
              )}
            </Form.Item>
          ) : (
            <Form.Item
              label="Case Status"
              name="case status"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              required={true}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                style={{ textTransform: "capitalize" }}
                value={CaseUpdateStatusState.requireInformationStatus}
                placeholder="Select Case Status"
                onChange={handleRequireInformationChange}
              >
                {requiresInformation &&
                  requiresInformation.map((eachStatus) => (
                    <Option
                      key={eachStatus._id}
                      value={eachStatus.name}
                      style={{ textTransform: "capitalize" }}
                    >
                      {eachStatus.displayName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="Select a template message as patient explanation"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group
              value={CaseUpdateStatusState.templateFilter}
              onChange={({ target }) => {
                setCaseUpdateStatusState((prev) => ({
                  ...prev,
                  selectedTemplateMessage: "",
                  templateFilter: target.value,
                }));
              }}
            >
              <Radio.Button value="All">All</Radio.Button>
              <Radio.Button value="My">My</Radio.Button>
              <Radio.Button value="Shared">Shared</Radio.Button>
              <Radio.Button value="Admin">Admin</Radio.Button>
            </Radio.Group>
            <Select
              size="large"
              placeholder="Add template message as expanation to patient"
              onChange={handleTemplateSelectionChange}
              showSearch
              filterOption={(input, option) => {
                return (
                  option.key.toLowerCase().includes(input.toLowerCase()) ||
                  option.value.toLowerCase().includes(input.toLowerCase())
                );
              }}
              value={CaseUpdateStatusState.selectedTemplateMessage}
              disabled={CaseUpdateStatusState.disableMessage}
            >
              {CaseUpdateStatusState.commonTemplateMessages
                .filter((template) => {
                  switch (CaseUpdateStatusState.templateFilter) {
                    case "Shared":
                      return (
                        !!template.ownerId &&
                        template.ownerId?._id !== user?._id
                      );
                    case "Admin":
                      return !template.ownerId;
                    case "My":
                      return template.ownerId?._id === user?._id;
                    default:
                      return true;
                  }
                })
                .map((eachTemplateMessage, index) => (
                  <Option
                    key={`${eachTemplateMessage.title}-${index}`}
                    value={compileHandlebars(eachTemplateMessage.message)}
                  >
                    {eachTemplateMessage.favoritedBy && (
                      <StarFilled style={{ color: "#7BAD7E" }} />
                    )}
                    {eachTemplateMessage.title}
                  </Option>
                ))}
              <Option key="custom-message" value={""}>
                Custom Message
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="Patient Explanation"
            label={
              <span style={{ color: "#7bad7b", fontWeight: "bold" }}>
                Explanation To Patient
              </span>
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Form.Item>
              <Checkbox
                checked={CaseUpdateStatusState.sendInformationCheckBox}
                onChange={({ target }) =>
                  setCaseUpdateStatusState((prev) => ({
                    ...prev,
                    sendInformationCheckBox: target.checked,
                  }))
                }
              >
                Send explanation to patient
              </Checkbox>
            </Form.Item>
            <AntTinyWrapper
              value={CaseUpdateStatusState.patientExplanation}
              onChange={handlePatientExplanationChange}
              disabled={CaseUpdateStatusState.disableMessage}
            />
          </Form.Item>
          {actionCaseStatus === CaseStatusEnum.REFER && (
            <Form.Item
              label={
                <span style={{ color: "#7bad7e", fontWeight: "bold" }}>
                  Rejection Reason (to be provided to reviewing physician)
                </span>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Input.TextArea
                name="Rejection Reason"
                value={CaseUpdateStatusState.rejectionReason}
                disabled={CaseUpdateStatusState.disableMessage}
                onChange={handleRejectionReasonChange}
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
