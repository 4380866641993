import Axios from "../Axios"
import config from "../../config";
import qs from 'querystring'

const { api } = config;

export default class API {
    static getToken() {
        const token = window.localStorage.getItem("token");
        return token;
    }

    static getPatientPrescriptions(patientPrescriberId) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/v2/prescriptions/patient-history?patientId=${patientPrescriberId}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static searchMedications(searchString) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/medications`,
            params: { name: searchString },
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getMedicationByNameAndStrength(name, strength) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/medications/medication`,
            params: { name, strength },
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getRxLookupForComparison() {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/v2/prescriptions/rx-lookup`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getPharmacybyId(pharmacyId) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/pharmacy/${pharmacyId}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static searchPharmacies(params) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/pharmacy`,
            method: "GET",
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getAllAvailableDispenseUnits() {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/medications/dispense-units`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getPharmacySpecialtyTypes() {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/pharmacy/specialties`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static searchAllergies(q) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/allergies`,
            method: "GET",
            params: { q },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getPatientAllergies(patientId) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/patient/${patientId}/allergies`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static addAllergiesToPatient(allergies) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/allergies`,
            method: "POST",
            data: { allergies },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getPatientPrescriptionHistory(patientId, options = {}) {
        const query = qs.stringify({...options, source: 'front-end'})

        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/patient/${patientId}?${query}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static updatePatient(patientId, update) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/patient/${patientId}`,
            method: "PUT",
            data: update,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static updatePatientDefaultPharmacy(patientId, pharmacyId) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/patient/${patientId}/default-pharmacy`,
            method: "POST",
            data: { pharmacyId },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getRxRequests(filter) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/rx-requests`,
            method: "GET",
            params: filter,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static approveOrDenyRxRequest(data) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/rx-requests`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static cancelPrescription(prescriptionId) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/prescriptions/${prescriptionId}`,
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getRxErrors() {
        const token = this.getToken();

        let url = `${api.baseUrl}/prescriptions/rx-errors`

        return Axios({
            url,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static acknowledgeError(errorId) {
        const token = this.getToken();

        return Axios({
            url: `${api.baseUrl}/prescriptions/rx-errors/${errorId}`,
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static resendPrescription({ prescriptionId, PIN }){
        const token = this.getToken();
        let url = `${api.baseUrl}/prescriptions/${prescriptionId}`

        return Axios({
            url,
            method: "PUT",
            data: { PIN },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static rollBackCase(caseId, errorId, providerId) {
        const token = this.getToken();

        return Axios({
            url: `${api.baseUrl}/prescriptions/rx-errors/rollback/${caseId}`,
            method: "PUT",
            data: { caseId, errorId, providerId },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static getRxRequestDenialCodes() {
        const token = this.getToken();

        return Axios({
            url: `${api.baseUrl}/prescriptions/rx-requests/denial-codes`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    static reconcilePharmacyPatient(data) {
        const token = this.getToken();

        return Axios({
            url: `${api.baseUrl}/prescriptions/patient/reconcile`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }
}
