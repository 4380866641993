import React from 'react'
import { Skeleton, } from 'antd'

import { useCaseViewContext } from '../../../../../../context/CaseViewContext'

import { PrescriptionActionModal } from './PrescriptionAction.modal';
import { Prescription } from './Prescription'

import { usePrescriptionsAndActions } from './hooks/usePrescriptionsAndActions';

export const PrescribedTreatments = () => {

    const { 
        caseDetails, 
        activeRequest, 
        provider,
     } = useCaseViewContext()

     const {
        setPrescriptionAndAction,
        deselectRx,
        prescriptionActionState,
        rxHistory,
     } = usePrescriptionsAndActions({
        _case: caseDetails,
        activeRequest,
     })

    if (!rxHistory || rxHistory.length === 0) {
        return (
            <div>
                <p>No Prescribed Medications</p>
            </div>
        )
    }

    const { 
        selectedPrescription, 
        action, 
        handler 
    } = prescriptionActionState

    return (
        <Skeleton loading={!rxHistory}>
            {
                (rxHistory || [])
                    .sort((a, b) => new Date(b.signedAt) - new Date(a.signedAt))
                    .map(prescription => (
                    <Prescription
                        key={prescription.prescriptionId}
                        prescription={prescription}
                        setPrescriptionAndAction={setPrescriptionAndAction}
                    />
                ))
            }
            {
                prescriptionActionState.selectedPrescription && 
                prescriptionActionState.action && 
                    <PrescriptionActionModal
                        prescription={selectedPrescription}
                        action={action}
                        handler={handler}
                        providerHasPinSet={provider?.pinSet}
                        deselectRx={deselectRx}
                    />
            }
        </Skeleton>
    )
}