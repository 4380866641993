export const PrescriptionActions = {
    RESEND: 'resend',
    CANCEL: 'cancel'
}

export const MedicationStatus = {
    INACTIVE: 'Inactive',
    DISCONTINUED: 'Discontinued',
    CANCEL_REQUESTED: 'CancelRequested',
    CANCEL_PENDING: 'CancelPending',
    CANCEL: 'Cancel',
    CANCELED: 'Canceled',
    CANCEL_DENIED: 'CancelDenied',
}

export const PharmacyStatus = {
    PHARMACY_VERIFIED: 'PharmacyVerified',
}