import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined, } from "@ant-design/icons";
import caseQueueActions from "../../redux/caseQueue/actions"
import { PhysicianTagEnum } from "../../types/enums"
import { CaseFilterMap } from "../../constants/CaseFilter";
import SelectStatus from "./SelectStatus";
import Title from "./Title"
import QueueTable from "./QueueTable";

const TableDataMap = {
  STATUS_ICON: 'statusIcon',
  CASE_ID: CaseFilterMap.CASE_ID,
  PATIENT_NAME: `${CaseFilterMap.PATIENT_FIRST_NAME} ${CaseFilterMap.PATIENT_LAST_NAME}`,
  TELEMEDICINE_CATEGORY_ID: CaseFilterMap.TELEMEDICINE_CATEGORY_ID,
  STATE_ID: CaseFilterMap.STATE_ID,
  CREATED_DATE: CaseFilterMap.CREATED_DATE,
  STATUS: CaseFilterMap.STATUS,
  REQUEST_ID: 'requestId',
}

const CaseQueue = () => {
  const dispatch = useDispatch();
  const physicianDetails = useSelector(state => state.physicianDetails.physicianDetails);
  const caseQueueLoading = useSelector(state => state.caseQueue.caseQueueLoading);
  const caseQueueStatusFilter = useSelector(state => state.caseQueue.caseQueueStatusFilter);

  const isLeadPhysician = physicianDetails?.tags?.includes(PhysicianTagEnum.LEAD_PHYSICIAN)
  const sendSMSNotifications = physicianDetails?.preferences?.sendQueueSMSNotifications

  const fetchCaseQueue = useCallback(() => {
    const caseFilter = {
      status: caseQueueStatusFilter,
    };

    dispatch(caseQueueActions.getCaseQueue(caseFilter, sendSMSNotifications));
    if (isLeadPhysician) {
      dispatch(caseQueueActions.getCaseQueueCount('internal'));
    }
  }, [caseQueueStatusFilter, isLeadPhysician, sendSMSNotifications, dispatch]);

  useEffect(() => {
    fetchCaseQueue()
  }, [fetchCaseQueue]);

  return (
    <div className="consulted-case">
      <div style={{ alignItems: 'center'}}>
        <Title/>
        <SelectStatus/>
      </div>
      <div className="consulted-case-no-of-patient-list-data" style={{ marginTop: 0 }}>
        <Spin
          size="large"
          spinning={caseQueueLoading}
          indicator={<LoadingOutlined spin style={{ color: '#7bad7b'}} />}
        >
          <div>
            <QueueTable TableDataMap={TableDataMap}/>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default CaseQueue;
