import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment-timezone'
import { capitalize } from "lodash";
import caseQueueActions from "../../redux/caseQueue/actions"
import { StyledTable } from "./StyledComponents";
import StatusIcon from "./StatusIcon";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { getDateFieldSorter } from "../../utils/sorters";
import PhysicianApi from "../../axios/physician/PhysicianApi";
import { userConstants } from "../../constants";
import qs from "querystring";
import { message } from "antd";
import { useHistory } from "react-router-dom";

const createTableColumns = (TableDataMap) => {
  const columns = [
    {
      colSpan: 1,
      key: TableDataMap.STATUS_ICON,
      width: 60,
      render: (status) => <StatusIcon status={status}/>,
    },
    {
      title: "Case No",
      colSpan: 1,
      key: TableDataMap.CASE_ID,
      width: 230,
    },
    {
      title: "Patient Name",
      key: TableDataMap.PATIENT_NAME,
      width: 180,
    },
    {
      title: "Category Name",
      key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
      width: 250,
    },
    {
      title: "State",
      key: TableDataMap.STATE_ID,
      width: 140,
    },
    {
      title: "Created Date",
      key: TableDataMap.CREATED_DATE,
      width: 230,
      render: val => (
        <div>
          <div>{dateTimeFormatter(val)}</div>
          <div>({moment(val).fromNow()})</div>
        </div>
      ),
      sorter: getDateFieldSorter(TableDataMap.CREATED_DATE),
    },
    {
      title: "Status",
      key: TableDataMap.STATUS,
      render: (status) => status.split(' ').map(capitalize).join(' '),
    }
  ];

  columns.forEach((c) => (c.dataIndex = c.key));
  return columns;
};

const QueueTable = ({ TableDataMap }) => {
    const caseQueue = useSelector(state => state.caseQueue.caseQueue);
    const dispatch = useDispatch();
    const history = useHistory();

    const onChange = (_, __, sorter) => {
        dispatch(caseQueueActions.setSortDirection(sorter.order || 'ascend'));
    }

    const columns = useMemo(() => createTableColumns(TableDataMap), [TableDataMap]);

    const pushRouteCaseDetails = ({ caseId, requestId }) => {
        const path = `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`;
        const search = requestId ? qs.stringify({ requestId }) : '';
        const from = history.location.pathname.split('/').pop() ;
        history.push(`${path}?${search}`, { from });
    };

    const handleCaseListClick = (event, record) => {
      event.stopPropagation();
      const { caseId, requestId } = record;
      PhysicianApi
            .startConsultation({
                caseId,
                consultationStart: true,
                acceptAsLead: false
            })
            .then(({ data }) => {
                if (data?.success) {
                     pushRouteCaseDetails({ caseId, requestId });
                } else {
                    const errorMessage = data?.message || "Unable to Start Consult. Please try again later!"
                    message.error(errorMessage);
                }
            })
            .catch(({ response }) => {
                const errorMessage = response?.data?.message || "Error occured while Starting Consultation. Please try again later!"
                message.error(errorMessage);
            });
    };

    const casesForTable = caseQueue.map((c) => {
      return {
        key: c._id,
        rowKey: c._id,
        [TableDataMap.STATUS_ICON]: c.status,
        [TableDataMap.CASE_ID]: c._id,
        [TableDataMap.PATIENT_NAME]: c.patient?.name,
        [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.category?.name,
        [TableDataMap.STATE_ID]: c.state,
        [TableDataMap.CREATED_DATE]: c.createdAt,
        [TableDataMap.STATUS]: c.status,
        [TableDataMap.REQUEST_ID]: c.request?._id,
      }
    })

    return (
        <StyledTable
          className="case-queue-table"
          columns={columns}
          dataSource={casesForTable}
          scroll={{ x: 900 }}
          onChange={onChange}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 50
          }}
          onRow={(record) => ({
            onClick: (event) => handleCaseListClick(event, record),
          })}
        />
    )
}

export default QueueTable;
