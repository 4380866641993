import React, { useState } from 'react'
import { Modal, Button, Typography, Input, message } from 'antd'

const { Text } = Typography

const { PrescriptionActions } = require('./enums')

const Detail = ({ header, children, style={} }) => (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between', ...style }}>
        {header && <Text style={{ marginRight: '10px', fontSize: '.75rem', textTransform: 'capitalize' }}>{header}</Text>}
        {children}
    </div>
)

export const PrescriptionActionModal = ({
    prescription,
    action,
    handler,
    providerHasPinSet,
    deselectRx
}) => {
    const [PIN, setPIN] = useState('')

    const handleAction = () => {
        const PINRequirementFullfilled = !providerHasPinSet || (providerHasPinSet || PIN.length === 4)
        if (action  === 'resend') {
            if (!PINRequirementFullfilled) {
                return message.error('PIN is required to resend prescription')
            }
            handler({ prescriptionId: prescription.prescriptionId, PIN })
            setPIN('')
        }
    }

    const buttonType = action === PrescriptionActions.CANCEL ? 'danger' : 'primary'
    const showPINInput = action === PrescriptionActions.RESEND && providerHasPinSet
    
    const footer = [
        <div style={{ display: 'flex', justifyContent: showPINInput ? 'space-between' : 'right' }}>
            {
                showPINInput && (
                    <div>
                        PIN: 
                        <Input
                            style={{ width: '75px', marginLeft: '10px', textTransform: 'capitalize' }}
                            maxLength={4}
                            type="password"
                            size="small"
                            label="PIN"
                            value={PIN}
                            onChange={e => setPIN(e.target.value)}
                        />
                    </div>
                )
            }
            <Button 
                style={{ textTransform: 'capitalize' }} 
                key="submit" type={buttonType} 
                size="small" 
                disabled={PIN.length < 4}
                onClick={handleAction}
            >
                {action} Rx
            </Button>
        </div>
    ]
    
    return (
            <Modal
                title={<div style={{ textTransform: 'capitalize'}}>{action} Prescription?</div>}
                visible
                centered
                width={280}
                onCancel={deselectRx}
                footer={footer}
            >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <Detail header='Drug Name'>
                            <div style={{ width: '100%', fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.name}</div>
                        </Detail>
                        <Detail header='Strength' style={{ flexDirection: 'column'}}>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.strength || 'See above'}</div>
                        </Detail>
                        <Detail header='Quantity' style={{ flexDirection: 'column'}}>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.quantity}</div>
                        </Detail>
                        <Detail header='Refills' style={{ flexDirection: 'column'}}>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.refills}</div>
                        </Detail>
                        <Detail header='Days Supply' style={{ flexDirection: 'column'}}>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.daysSupply}</div>
                        </Detail>
                        <Detail header='Directions'>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.directions}</div>
                        </Detail>
                    </div>
            </Modal>
    )
}