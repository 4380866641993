import { useState, useEffect, } from 'react';
import { message } from 'antd'

import PrescriptionsAPI from '../../../../../../../../../axios/prescription/PrescriptionsApi'

import { PrescriptionActions } from '../enums'

export const usePrescriptionsAndActions = ({ 
    _case,
    activeRequest, 
}) => {
    const [rxHistory, setRxHistory] = useState(null)

    const [prescriptionActionState, setPrescriptionActionState] = useState({
        selectedPrescription: null,
        action: null,
    })

    const getPatientPrescriptionHistory = async () => {
        const activeRequestIndex = _case.requests.map(request => request._id).indexOf(activeRequest._id) || 0
        const nextRequest = _case.requests[activeRequestIndex + 1]

        const rxWindow = {
            startDate: activeRequest.createdAt,
            ...(nextRequest?.createdAt && { endDate: nextRequest.createdAt })
        }

        try {
            const { data: { prescriptions } } = await PrescriptionsAPI.getPatientPrescriptionHistory(_case?.patientId, rxWindow)
            const prescribedMedicationsForRxRequest = prescriptions.filter(rx => rx.prescriberStatus?.toLowerCase() !== 'deleted')
            setRxHistory(prescribedMedicationsForRxRequest)
        } catch (e) {
            setRxHistory([])
            message.error('Error fetching patient Rx history')
            console.log(e)
        }
    }

    const handleCancelPrescription = async prescriptionId => {
        try {
            const { responseCode } = await PrescriptionsAPI.cancelPrescription(prescriptionId)
            if (!responseCode === '200') throw new Error('Error cancelling rx request prescription')

            const updatedRxHistory = rxHistory.map(rx => {
                if (rx.prescriptionId !== prescriptionId)
                    return rx
                return { ...rx, medicationStatus: 'CancelPending' }
            })

            setRxHistory(updatedRxHistory)
            setPrescriptionActionState(prev => ({
                ...prev,
                selectedPrescription: null
            }))
        } catch (e) {
            console.error(e)
            message.error('Error cancelling rx request prescription')
        }
    }

    const handleReSendPrescription = async ({ prescriptionId, PIN }) => {
        try {
            const { responseCode } = await PrescriptionsAPI.resendPrescription({ prescriptionId, PIN })
            if (!responseCode === '200') throw new Error('Error re-sending prescription')

            setPrescriptionActionState(prev => ({
                ...prev,
                selectedPrescription: null
            }))
        } catch (e) {
            console.error(e)
            message.error('Error cancelling rx request prescription')
        }
    }

    const setPrescriptionAndAction = ({ prescription, action, }) =>  {
        const prescriptionActionHandlers = {
            [PrescriptionActions.CANCEL]: handleCancelPrescription,
            [PrescriptionActions.RESEND]: handleReSendPrescription,
        }

        if (!prescriptionActionHandlers[action]) {
            return message.error('Action not supported')
        }

        setPrescriptionActionState(prev => ({
            ...prev,
            action,
            handler: prescriptionActionHandlers[action],
            selectedPrescription: prescription
        }))
    }


    const deselectRx = () => {
        setPrescriptionActionState(prev => ({
            ...prev,
            selectedPrescription: null
        }))
    }

    useEffect(() => {
        if (_case && activeRequest) {
            getPatientPrescriptionHistory()
        }
    }, [_case, activeRequest])

    return {
        getPatientPrescriptionHistory,
        handleCancelPrescription,
        handleReSendPrescription,
        setPrescriptionAndAction,
        deselectRx,
        prescriptionActionState,
        rxHistory,
    }
}