import React from 'react'
import moment from 'moment-timezone'

import { 
    DeleteOutlined,
    ReloadOutlined, 
} from '@ant-design/icons'

import { StyledButton } from '../../../../../Button'

import { useDispenseUnitContext } from '../../context/DispenseUniteContext'

import { 
    PrescriptionActions,
    MedicationStatus, 
    PharmacyStatus,
} from './enums'

export const Prescription = ({
    prescription,
    setPrescriptionAndAction,
}) => {
    const { getDispenseUnitById } = useDispenseUnitContext()

    const cancelled = [
        MedicationStatus.CANCEL_PENDING, 
        MedicationStatus.CANCEL, 
        MedicationStatus.CANCELED,
    ].includes(prescription.medicationStatus)

    const RED_BACKGROUND = 'rgba(255, 0, 0, .2)'
    const GREY_BACKGROUND = 'rgb(221, 221, 221)'

    const background = cancelled ? RED_BACKGROUND : GREY_BACKGROUND
    
    return (
        <div style={{
            width: '100%',
            padding: '20px',
            marginBottom: '20px',
            background,
            color: '#000',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <strong>{prescription.name}</strong>
                    {
                        ![
                            MedicationStatus.DISCONTINUED,
                            MedicationStatus.INACTIVE,
                            MedicationStatus.CANCEL_REQUESTED,
                            MedicationStatus.CANCEL_PENDING,
                            MedicationStatus.CANCEL_DENIED,
                        ].includes(prescription.medicationStatus) &&
                        prescription.pharmacyStatus !== PharmacyStatus.PHARMACY_VERIFIED &&
                        (
                            <div style={{ 
                                textTransform: 'capitalize', 
                                display: 'flex', 
                                gap: '5px'
                            }}>
                                <StyledButton
                                    size='small'
                                    Icon={ReloadOutlined}
                                    tooltip={`${PrescriptionActions.RESEND} Prescription`}
                                    color='green'
                                    onClick={() => setPrescriptionAndAction({prescription, action: PrescriptionActions.RESEND})}
                                />
                                <StyledButton
                                    size='small'
                                    Icon={DeleteOutlined}
                                    tooltip={`${PrescriptionActions.CANCEL} Prescription`}
                                    color='red'
                                    onClick={() => setPrescriptionAndAction({prescription, action: PrescriptionActions.CANCEL})}
                                />
                            </div>
                        )
                    }
                </div>
                <div style={{ fontSize: '12px', marginBottom: '5px'}}>Written Date: {moment(prescription.signedAt).format('M/DD/YYYY h:MM a')}</div>
            </div>
            
            <div style={{ display: 'flex', gap: '20px', marginBottom: '5px' }}>
                <span>{prescription.quantity} {getDispenseUnitById(prescription.dispenseUnitId)?.Name}(s)</span>
                <span>Refills: {prescription.refills}</span>
                <span>Supply: {prescription.daysSupply} days</span>
            </div>
            <div><strong>Directions:</strong> {prescription.directions}</div>
            <div><strong>Pharmacy Notes:</strong> {prescription.pharmacyNotes || "None"}</div>
            <div><strong>Pharmacy Status:</strong> {prescription.pharmacyStatus}</div>
            <div>
                <strong>Medication Status: </strong> 
                <span 
                    style={{ 
                        color: cancelled ? 'red' : 'black',
                        fontWeight: cancelled ? 'bold' : 'normal' 
                    }}
                >
                    {prescription.medicationStatus}
                </span>
            </div>
        </div>
    )
}